












import { Vue, Component } from 'vue-property-decorator';
import { receiveCoupon } from '@/api/user';

type ReceiveCouponParam = Parameters<typeof receiveCoupon>[0];
@Component({
  name: ''
})
export default class extends Vue {
  form = {} as ReceiveCouponParam;

  async onSubmit() {
    await receiveCoupon(this.form);
    this.form = {} as ReceiveCouponParam;
  }
}
